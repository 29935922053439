import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Layout } from "antd";
import React, { useState } from "react";
import Sidebar from "../component/Sidebar";
import { authenticate, logout } from "../utils/auth";

const { Header } = Layout;

const Main = ({ children }) => {
  const auth = authenticate();
  const [collapsed, setCollapsed] = useState(false);
  const items = [
    {
      key: "username",
      label: auth.username,
      disabled: true,
    },
    {
      key: "logout",
      label: "Logout",
    },
  ];
  const handleMenuClick = (e) => {
    if (e?.key === "logout") {
      logout();
    }
  };

  return (
    <Layout className="w-[100vw] h-[100vh]">
      <Sidebar collapsed={collapsed} />
      <Layout className="site-layout overflow-auto ">
        <Header
          style={{ padding: "0 16px", backgroundColor: "white" }}
          className="flex justify-between align-middle w-full">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <div className="flex ">
            <div className="font-bold">{auth?.name}</div>
          </div>
          <div>
            <Dropdown
              menu={{ items, onClick: handleMenuClick }}
              className="my-auto">
              <Avatar icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </Header>
        <div className="bg-white rounded-xl shadow-md p-9 m-5 min-h-fit!">
          {children}
        </div>
      </Layout>
    </Layout>
  );
};

export default Main;
