import { Button, Col, Row, Table, Typography } from "antd"
import React, { useEffect, useState } from "react"
import * as requests from "../utils/requests"
import { ReloadOutlined } from "@ant-design/icons"

const Dashboard = ({ hideHeader = false }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getInit()
  }, [])

  const getInit = async () => {
    setLoading(true)
    try {
      const res = await requests.getDashboard()
      setData(res?.data?.data || [])
    } catch (e) {
      setData([])
    }
    setLoading(false)
  }
  const columns = [
    {
      title: "ผู้ที่ขออนุญาติ",
      dataIndex: "admin_name",
      key: "admin_name",
      name: "admin_name",
    },
    {
      title: "ราคารวม",
      dataIndex: "sum_f",
      key: "sum_f",
      name: "sum_f",
    },
    {
      title: "ยอดรวม",
      dataIndex: "sum_k",
      key: "sum_k",
      name: "sum_k",
    },
    {
      title: "จำนวนคนที่ดูแล",
      dataIndex: "user_count",
      key: "user_count",
      name: "user_count",
    },
    {
      title: "จำนวนลูกค้าเก่า",
      dataIndex: "old_user_count",
      key: "old_user_count",
      name: "old_user_count",
    },
  ]

  return (
    <>
      {!hideHeader && <Typography.Title level={4}>Dashboard</Typography.Title>}
      <Row justify="end">
        <Col>
          <Button
            type="link"
            onClick={getInit}
            className="!text-blue-500"
            icon={<ReloadOutlined className="text-blue-500" />}
          />
        </Col>
      </Row>
      <Table columns={columns} loading={loading} dataSource={data} rowKey="admin_name" />
    </>
  )
}

export default Dashboard
