import { authenticate } from "./auth"

const ACCESS_PAGE = {
  LIST: "LIST",
  OLD_CLIENT: "OLD_CLIENT",
  USER_MANAGEMENT: "USER_MANAGEMENT",
  PERMISSION: "PERMISSION",
  DASHBOARD: "DASHBOARD",
  TRASH: "TRASH",
}
const FILTER = {
  FILTER_BY_DATE: "FILTER_BY_DATE",
  FILTER_BY_COLOR: "FILTER_BY_COLOR",
  FILTER_BY_ADMIN: "FILTER_BY_ADMIN",
}
const EDIT = { EDIT_RECORD: "EDIT_RECORD", EDIT_USER: "EDIT_USER" }
const CREATE = {
  CREATE_RECORD: "CREATE_RECORD",
  CREATE_USER: "CREATE_USER",
  CERATE_HISTORY: "CERATE_HISTORY",
}
const DELETE = {
  DELETE_RECORD: "DELETE_RECORD",
  DELETE_RECORD_SELECTED: "DELETE_RECORD_SELECTED",
  DELETE_USER: "DELETE_USER",
  DELETE_GREEN_RECORD: "DELETE_GREEN_RECORD",
  DELETE_TIME: "DELETE_TIME",
}
const EXPORT = { EXPORT_RECORD_TO_EXCEL: "EXPORT_RECORD_TO_EXCEL" }
const RESET = { RESET_OTP: "RESET_OTP", RESET_OTP_ALL: "RESET_OTP_ALL" }
const ASSIGN = { ASSIGN_ADMIN: "ASSIGN_ADMIN" }
const VIEW = {
  VIEW_RECORD_ADMIN_COLUMN: "VIEW_RECORD_ADMIN_COLUMN",
}

const EDIT_RECORD_COLUMN = {
  EDIT_RECORD_COLUMN_A: "a",
  EDIT_RECORD_COLUMN_B: "b",
  EDIT_RECORD_COLUMN_C: "c",
  EDIT_RECORD_COLUMN_D: "d",
  EDIT_RECORD_COLUMN_E: "e",
  EDIT_RECORD_COLUMN_F: "f",
  EDIT_RECORD_COLUMN_G: "g",
  EDIT_RECORD_COLUMN_H: "h",
  EDIT_RECORD_COLUMN_I: "i",
  EDIT_RECORD_COLUMN_J: "j",
  EDIT_RECORD_COLUMN_K1: "k_1",
  EDIT_RECORD_COLUMN_K: "k",
  EDIT_RECORD_COLUMN_L: "l",
  EDIT_RECORD_COLUMN_M: "m",
  EDIT_RECORD_COLUMN_N: "n",
  EDIT_RECORD_COLUMN_O: "o",
}

export const ABILITIES = {
  ...ACCESS_PAGE,
  ...EDIT,
  ...FILTER,
  ...CREATE,
  ...EXPORT,
  ...DELETE,
  ...RESET,
  ...ASSIGN,
  ...VIEW,
  ...EDIT_RECORD_COLUMN,
}
export const updateAbilities = (level) => {
  const abilities = []
  switch (level) {
    case "1":
      //Super
      abilities.push(ACCESS_PAGE.USER_MANAGEMENT)
      abilities.push(ACCESS_PAGE.LIST)
      abilities.push(ACCESS_PAGE.OLD_CLIENT)
      abilities.push(ACCESS_PAGE.PERMISSION)
      abilities.push(ACCESS_PAGE.DASHBOARD)
      abilities.push(ACCESS_PAGE.TRASH)

      abilities.push(FILTER.FILTER_BY_DATE)
      abilities.push(FILTER.FILTER_BY_COLOR)
      abilities.push(FILTER.FILTER_BY_ADMIN)

      abilities.push(CREATE.CREATE_RECORD)
      abilities.push(EDIT.EDIT_RECORD)
      abilities.push(DELETE.DELETE_RECORD)
      abilities.push(DELETE.DELETE_RECORD_SELECTED)
      abilities.push(EDIT.EDIT_TEL)
      abilities.push(EXPORT.EXPORT_RECORD_TO_EXCEL)
      abilities.push(DELETE.DELETE_GREEN_RECORD)
      abilities.push(DELETE.DELETE_TIME)
      abilities.push(ASSIGN.ASSIGN_ADMIN)
      abilities.push(VIEW.VIEW_RECORD_ADMIN_COLUMN)
      abilities.push(CREATE.CERATE_HISTORY)

      abilities.push(CREATE.CREATE_USER)
      abilities.push(EDIT.EDIT_USER)
      abilities.push(DELETE.DELETE_USER)

      abilities.push(RESET.RESET_OTP)
      abilities.push(RESET.RESET_OTP_ALL)

      Object.keys(EDIT_RECORD_COLUMN).forEach((i) =>
        abilities.push(EDIT_RECORD_COLUMN[i])
      )
      break

    case "2":
      //Manager1
      abilities.push(ACCESS_PAGE.USER_MANAGEMENT)
      abilities.push(ACCESS_PAGE.LIST)
      abilities.push(ACCESS_PAGE.PERMISSION)
      abilities.push(ACCESS_PAGE.DASHBOARD)
      abilities.push(ACCESS_PAGE.TRASH)

      abilities.push(FILTER.FILTER_BY_DATE)
      abilities.push(FILTER.FILTER_BY_COLOR)
      abilities.push(FILTER.FILTER_BY_ADMIN)

      abilities.push(CREATE.CREATE_RECORD)
      abilities.push(EDIT.EDIT_RECORD)
      abilities.push(DELETE.DELETE_RECORD)
      abilities.push(EDIT.EDIT_TEL)
      abilities.push(EXPORT.EXPORT_RECORD_TO_EXCEL)
      abilities.push(DELETE.DELETE_GREEN_RECORD)
      abilities.push(DELETE.DELETE_TIME)
      abilities.push(ASSIGN.ASSIGN_ADMIN)
      abilities.push(VIEW.VIEW_RECORD_ADMIN_COLUMN)
      abilities.push(CREATE.CERATE_HISTORY)

      abilities.push(CREATE.CREATE_USER)
      abilities.push(EDIT.EDIT_USER)
      abilities.push(DELETE.DELETE_USER)

      abilities.push(RESET.RESET_OTP)
      abilities.push(RESET.RESET_OTP_ALL)

      Object.keys(EDIT_RECORD_COLUMN).forEach((i) =>
        abilities.push(EDIT_RECORD_COLUMN[i])
      )
      break

    case "3":
      //Manager
      abilities.push(ACCESS_PAGE.DASHBOARD)
      abilities.push(ACCESS_PAGE.LIST)
      abilities.push(FILTER.FILTER_BY_DATE)
      abilities.push(FILTER.FILTER_BY_COLOR)
      abilities.push(FILTER.FILTER_BY_ADMIN)

      abilities.push(CREATE.CREATE_RECORD)
      abilities.push(EDIT.EDIT_RECORD)
      abilities.push(EDIT.EDIT_TEL)
      abilities.push(EXPORT.EXPORT_RECORD_TO_EXCEL)
      abilities.push(DELETE.DELETE_GREEN_RECORD)
      abilities.push(DELETE.DELETE_TIME)
      abilities.push(ASSIGN.ASSIGN_ADMIN)
      abilities.push(VIEW.VIEW_RECORD_ADMIN_COLUMN)
      abilities.push(CREATE.CERATE_HISTORY)

      // Can update all column
      Object.keys(EDIT_RECORD_COLUMN).forEach((i) =>
        abilities.push(EDIT_RECORD_COLUMN[i])
      )

      // abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_K)
      // abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_L)
      // abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_M)
      // abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_O)
      break

    default:
      //Admin
      abilities.push(ACCESS_PAGE.LIST)
      abilities.push(FILTER.FILTER_BY_DATE)
      abilities.push(FILTER.FILTER_BY_COLOR)

      abilities.push(EDIT.EDIT_RECORD)
      abilities.push(DELETE.DELETE_GREEN_RECORD)
      abilities.push(EXPORT.EXPORT_RECORD_TO_EXCEL)

      abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_G)
      abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_H)
      abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_I)
      abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_J)
      // EDIT_RECORD_COLUMN_K1
      abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_K)
      abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_K1)
      
      abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_L)
      abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_M)
      abilities.push(EDIT_RECORD_COLUMN.EDIT_RECORD_COLUMN_O)

      break
  }
  return abilities
}

export const checkAbilities = (abilities) => {
  const auth = authenticate()
  return !!auth?.abilities?.filter((o1) => abilities?.some((o2) => o1 === o2))
    ?.length
}
