import { updateAbilities } from "./permissions"
import * as requests from "./requests"

export const login = async ({ username, password }) => {
  try {
    const { data } = await requests.login(username, password)
    window.localStorage.setItem("level", data?.level)
    window.localStorage.setItem("access_token", data?.access_token)
    window.localStorage.setItem("refresh_token", data?.refresh_token)
    window.localStorage.setItem("level_name", data?.level_name)
    window.localStorage.setItem("username", data?.username)
    window.localStorage.setItem("name", data?.name)
    return authenticate()
  } catch (e) {
    return e
  }
}

export const logout = () => {
  window.localStorage.removeItem("level")
  window.localStorage.removeItem("access_token")
  window.localStorage.removeItem("refresh_token")
  window.localStorage.removeItem("level_name")
  window.localStorage.removeItem("username")
  window.localStorage.removeItem("name")
  window.location.reload()
  if (window?.ipcRenderer) {
    window.ipcRenderer.send("reload-window")
  }
}

export const authenticate = () => {
  const level = window.localStorage.getItem("level")
  const accessToken = window.localStorage.getItem("access_token")
  const refreshToken = window.localStorage.getItem("refresh_token")
  const username = window.localStorage.getItem("username")
  const name = window.localStorage.getItem("name")
  const abilities = updateAbilities(level)
  if (level && accessToken && refreshToken) {
    return {
      isLogged: true,
      level,
      accessToken,
      refreshToken,
      abilities,
      username,
      name,
    }
  } else {
    return { isLogged: false }
  }
}
