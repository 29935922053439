import {
  CopyOutlined,
  ReloadOutlined,
  RetweetOutlined,
} from "@ant-design/icons"
import { Button, Input, Popconfirm, Table, Typography } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import notification from "../component/notification"
import * as requests from "../utils/requests"
import { colorItem } from "./List"
const Trash = () => {
  const [loading, setLoading] = useState(false)
  const [colorList, setColorList] = useState([])
  const [list, setList] = useState([])
  const [filtered, setFiltered] = useState([])
  const [copying, setCopying] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState("")

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  useEffect(() => {
    getColorList()
    getList()
  }, [])

  const getColorList = async () => {
    try {
      const res = await requests.getColor()
      setColorList(res?.data?.data || [])
    } catch (e) {
      setColorList([])
    }
  }
  const getList = async () => {
    setLoading(true)
    try {
      const res = await requests.getTrashedList()
      setList(res?.data?.data || [])
      setFiltered(res?.data?.data || [])
    } catch (e) {
      setList([])
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = () => {
    const temp = list?.filter((d) => {
      const tempData = { ...d }
      delete tempData.id
      delete tempData.admin
      delete tempData.o
      const valueConcat = Object.values(tempData).join("")
      return valueConcat.includes(searchKeyword)
    })
    setFiltered(searchKeyword ? temp : list)
  }
  const columns = useMemo(() => {
    const revert = async (id) => {
      setLoading(true)
      try {
        id && (await requests.revertList(id))
        notification("success", "เรียกคืนสำเร็จ")
      } catch (e) {
        notification("error", "เรียกคืนไม่สำเร็จ")
      } finally {
        getList()
      }
    }
    return [
      {
        title: "ลำดับ",
        dataIndex: "no",
        key: "no",
        name: "no",
        editable: false,
        show: true,
      },
      {
        title: "ID(รหัสลูกค้า)",
        dataIndex: "id",
        key: "id",
        name: "id",
      },
      {
        title: "Admin",
        dataIndex: "adminName",
        key: "adminName",
        name: "adminName",
      },

      {
        title: "ปจ",
        dataIndex: "d",
        key: "d",
        name: "d",
      },
      {
        title: "ชื่อ",
        dataIndex: "e",
        key: "e",
        name: "e",
      },
      {
        title: "ราคา (ร)",
        dataIndex: "f_1",
        key: "f_1",
        name: "f_1",
        //   editable: canEditF,
        inputType: "text",
        show: true,
      },
      {
        title: "ราคา(ด)",
        dataIndex: "f",
        key: "f",
        name: "f",
      },
      {
        title: "ลน.",
        dataIndex: "g",
        key: "g",
        name: "g",
      },
      {
        title: "ปด.",
        dataIndex: "h",
        key: "h",
        name: "h",
      },
      {
        title: "ว.ค้าง",
        dataIndex: "i",
        key: "i",
        name: "i",
      },
      {
        title: "ก/ค",
        dataIndex: "j",
        key: "j",
        name: "j",
      },
      {
        title: "โอน(ร)",
        dataIndex: "k_1",
        key: "k_1",
        name: "k_1",
        //   editable: canEditK,
        inputType: "text",
        show: true,
      },
      {
        title: "โอน(ด)",
        dataIndex: "k",
        key: "k",
        name: "k",
      },
      {
        title: "เวลา",
        dataIndex: "l",
        key: "l",
        name: "l",
      },
      {
        title: "ยอดล้ม",
        dataIndex: "amount_down",
        key: "amount_down",
        name: "amount_down",
        // editable: canEditL,
        inputType: "text",
        show: true,
      },
      {
        title: "วันส่ง (ร)",
        dataIndex: "send_date_count",
        key: "send_date_count",
        name: "send_date_count",
        // editable: canEditL,
        inputType: "text",
        show: true,
      },
      {
        title: "Note",
        dataIndex: "m",
        key: "m",
        name: "m",
      },
      {
        title: "โทร",
        dataIndex: "n",
        key: "n",
        name: "n",
      },
      {
        title: "สี",
        dataIndex: "o",
        key: "o",
        name: "o",

        render: (i) => colorItem(colorList?.find((c) => c?.id === i)),
      },
      {
        title: "",
        dataIndex: "amend",
        key: "amend",
        name: "amend",
        fixed: "right",
        render: (_, record) => (
          <Popconfirm
            onConfirm={() => {
              revert(record.id)
            }}
            title="ต้องการเรียกคืนรายการนี้?"
            okText="เรียกคิืน"
            cancelTextText="ยกเลิก"
          >
            <Button
              type="link"
              className="!text-blue-600 bg-white rounded-full bg-opacity-50"
              icon={<RetweetOutlined className="text-blue-600" />}
            />
          </Popconfirm>
        ),
      },
    ]
  }, [colorList])

  const handleCopySelectedAsTextToPasteInXls = () => {
    setCopying(true)
    try {
      const table = document?.createElement("table")
      selectedRowKeys
        .sort((a, b) => (a < b ? -1 : 1))
        .map((v) => list.find((d) => d.id === v))
        .forEach((v) => {
          const cols = ["d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n"]
          const row = table.insertRow()
          const bgColor = colorList?.find((c) => c?.id === v?.o)?.color_code
          row.style.backgroundColor = bgColor
          cols?.forEach((c) => {
            const text = v?.[c]?.trim() || ""
            const cell = row.insertCell()
            cell.textContent = text
          })
        })
      document?.body?.appendChild(table)
      const range = document?.createRange()
      range?.selectNode(table)
      window.getSelection().addRange(range)
      document?.execCommand("copy")
      window?.getSelection()?.removeAllRanges()
      table?.remove()
    } catch (e) {
    } finally {
      setCopying(false)
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  return (
    <div className="flex flex-col">
      <Typography.Title level={4}>รายการที่ลบ</Typography.Title>
      <div className="flex justify-between gap-5">
        <Input.Search
          placeholder="ค้นหา"
          disabled={loading}
          allowClear
          value={searchKeyword}
          onSearch={handleSearch}
          onChange={(e) => setSearchKeyword(e?.target?.value)}
        />
        <div className="flex justify-end gap-3 mb-3">
          <Button
            onClick={handleCopySelectedAsTextToPasteInXls}
            loading={copying}
            icon={<CopyOutlined />}
            disabled={selectedRowKeys.length === 0}
          >
            คัดลอก
          </Button>
          <Button
            onClick={() => {
              getList()
            }}
            type="link"
            className="!text-blue-500 w-fit"
            icon={<ReloadOutlined className="text-blue-500" />}
          />
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={filtered}
        loading={loading}
        rowKey="id"
        scroll={{ x: "max-content" }}
        pagination={{
          showSizeChanger: true,
          total: filtered?.length,
          pageSizeOptions: [10, 20, 50, 100, filtered?.length],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </div>
  )
}

export default Trash
