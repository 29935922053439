import React from "react"
import { Button as AntButton } from "antd"
const Button = ({ children = "", onClick, className = "", type = "primary", ...props }) => {
  const style = {
    primary: "bg-blue-500 text-white hover:bg-blue-600 hover:!text-white",
    dashed: "bg-transparent border-blue-500 text-blue-500 hover:!text-blue-600 hover:!border-blue-600",
  }
  return (
    <AntButton
      onClick={onClick}
      type={type}
      className={`${style[type]} rounded-md p-2 text-white flex justify-center items-center ${className}`}
      {...props}>
      {children && children}
    </AntButton>
  )
}

export default Button
