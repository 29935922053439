import {
  DashboardOutlined,
  DeleteOutlined,
  LockOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { Layout, Menu } from "antd"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { config } from "../utils/config"
import { ABILITIES, checkAbilities } from "../utils/permissions"
const { Sider } = Layout

const Sidebar = ({ collapsed }) => {
  const items = [
    {
      icon: <DashboardOutlined />,
      label: "Dashboard",
      key: "/dashboard",
      can: [ABILITIES.DASHBOARD],
    },
    {
      icon: <UnorderedListOutlined />,
      label: "รายการ",
      key: config.defaultListPath,
      can: [ABILITIES.LIST],
    },

    {
      icon: <DeleteOutlined />,
      label: "รายการที่ลบ",
      key: "/trash",
      can: [ABILITIES.TRASH],
    },
    {
      icon: <UserOutlined />,
      label: "จัดการผู้ใช้",
      key: "/users",
      can: [ABILITIES.USER_MANAGEMENT],
    },
    {
      icon: <LockOutlined />,
      label: "ขออนุญาติ",
      key: "/tel-permission",
      can: [ABILITIES.PERMISSION],
    },
  ]
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const currentPath =
    items?.find((i) => i?.key?.startsWith(pathname))?.key || ""
  const filteredItem = items.filter(({ can }) => {
    const show = can ? checkAbilities(can) : true
    return show
  })
  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="text-white flex justify-center items-center p-2 text-center">
        MEGA PROJECT
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[currentPath]}
        onClick={({ key }) => {
          navigate(key)
        }}
        items={filteredItem}
      />
    </Sider>
  )
}

export default Sidebar
