import { notification as antdNotification } from "antd"

const notification = (type, message, description, duration) => {
  antdNotification.open({
    message,
    description,
    type,
    duration,
  })
}

export default notification
