import { Form, Input } from "antd"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Button from "../component/Button"
import notification from "../component/notification"
import { login } from "../utils/auth"
import { config } from "../utils/config"

const Login = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const handleSubmit = async () => {
    const values = await form.validateFields()
    if (values) {
      const res = await login(values)
      if (res?.isLogged) {
        const from = location?.state?.from
        if (from && from !== "/list") {
          navigate(from)
        } else {
          navigate(config.defaultListPath)
        }
        notification("success", "ลงชื่อเข้าใช้สำเร็จ")
      } else {
        notification("error", "ลงชื่อเข้าใช้ไม่สำเร็จ")
      }
    }
  }
  const handleOnEnter = (e) => {
    if (e.keyCode === 13) {
      handleSubmit()
    }
  }
  return (
    <div className="w-[100vw] h-[100vh] flex justify-center align-middle">
      <div className="bg-white w-96 m-auto flex align-start items-center p-10 rounded-xl shadow-md flex-col">
        <h2 className="text-3xl font-bold mb-8">เข้าสู่ระบบ</h2>
        <Form form={form} layout="vertical" className="w-full">
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "กรุณากรอก Username!",
              },
            ]}
          >
            <Input placeholder="Username" onKeyDown={handleOnEnter} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "กรุณากรอก Password!",
              },
            ]}
          >
            <Input.Password placeholder="Password" onKeyDown={handleOnEnter} />
          </Form.Item>
        </Form>
        <Button onClick={handleSubmit} className="w-full mt-3" type="primary">
          เข้าสู่ระบบ
        </Button>
      </div>
    </div>
  )
}

export default Login
