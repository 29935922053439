import { Button, Row, Table, Typography, Col, Modal, Form, Input, Select, Popconfirm, QRCode } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import * as requests from "../utils/requests"
import { ABILITIES, checkAbilities } from "../utils/permissions"
import { UserAddOutlined } from "@ant-design/icons"
import { EditFilled, DeleteFilled, QrcodeOutlined, ReloadOutlined } from "@ant-design/icons"
import notification from "../component/notification"
const AmendUserModal = ({ visible, mode, handleSubmit, onCancel, data }) => {
  const [form] = Form.useForm()
  const onSubmit = async () => {
    const values = await form.validateFields()
    if (values) {
      if (mode === "update") {
        values.userId = data.user_id
        delete values.username
      }
      handleSubmit(values)
    }
  }
  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const rules = [{ required: true }]
  return (
    <Modal
      open={visible}
      title={mode === "create" ? "สร้างผู้ใช้งาน" : "แก้ไขผู้ใช้งาน"}
      onOk={onSubmit}
      onCancel={onCancel}>
      <Form form={form}>
        <Form.Item name="username" label="Username" rules={rules}>
          <Input placeholder="กรอก username" disabled={mode === "update"} />
        </Form.Item>
        <Form.Item name="name" label="ชื่อ" rules={rules}>
          <Input placeholder="กรอกชื่อ" />
        </Form.Item>
        <Form.Item name="level" label="Level" rules={rules}>
          <Select
            placeholder="เลือก Level"
            options={[
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
const Users = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const initModal = { mode: "", visible: false, handleSubmit: () => {}, data: null }
  const [modal, setModal] = useState(initModal)

  const canCreate = useMemo(() => checkAbilities([ABILITIES.CREATE_USER]), [])
  const canEdit = useMemo(() => checkAbilities([ABILITIES.EDIT_USER]), [])
  const canDelete = useMemo(() => checkAbilities([ABILITIES.DELETE_USER]), [])
  const canResetOtp = useMemo(() => checkAbilities([ABILITIES.RESET_OTP]), [])

  const getInit = async () => {
    setLoading(true)
    try {
      const res = await requests.getAllUser()
      if (res?.data?.data) {
        setData(res.data.data)
      }
    } catch (e) {}
    setLoading(false)
  }
  useEffect(() => {
    getInit()
  }, [])

  const handleCreate = async (values) => {
    try {
      await requests.createUser(values)
      notification("success", "สร้างผู้ใช้สำเร็จ")
      getInit()
      setModal(initModal)
    } catch (e) {
      notification("error", "สร้างผู้ใช้ไม่สำเร็จ")
    }
  }
  const handleUpdate = async (values) => {
    try {
      await requests.updateUser(values)
      notification("success", "แก้ไขผู้ใช้สำเร็จ")
      getInit()
      setModal(initModal)
    } catch (e) {
      notification("error", "แก้ไขผู้ใช้ไม่สำเร็จ")
    }
  }
  const handleDelete = async (id) => {
    try {
      await requests.deleteUser(id)
      notification("success", "ลบผู้ใช้งานสำเร็จ")
      getInit()
    } catch (e) {
      notification("error", "ลบผู้ใช้งานไม่สำเร็จ")
    }
  }
  const handleResetOtp = async (id) => {
    try {
      await requests.resetUserOtp(id)
      notification("success", "Reset OTP สำเร็จ")
      getInit()
    } catch (e) {
      notification("error", "Reset OTP ไม่สำเร็จ")
    }
  }
  const handleResetAllUserOtp = async () => {
    try {
      await requests.resetAllUserOtp()
      notification("success", "Reset OTP สำเร็จ")
      getInit()
    } catch (e) {
      notification("error", "Reset OTP ไม่สำเร็จ")
    }
  }

  return (
    <>
      <Typography.Title level={4}>จัดการผู้ใช้</Typography.Title>
      <Row justify="end" gutter={[8, 8]} className="mb-3">
        {canCreate && (
          <Col>
            <Popconfirm
              placement="top"
              title={"ยืนยัน Reset OTP ทั้งหมด"}
              onConfirm={handleResetAllUserOtp}
              okText="Yes"
              cancelText="No">
              <Button type="dashed" icon={<QrcodeOutlined />}>
                Reset OTP ทั้งหมด
              </Button>
            </Popconfirm>
          </Col>
        )}
        {canCreate && (
          <Col>
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              onClick={() => {
                setModal({ mode: "create", visible: true, handleSubmit: handleCreate, data: null })
              }}>
              สร้างผู้ใช้งาน
            </Button>
          </Col>
        )}
        <Col>
          <Button
            type="link"
            onClick={() => {
              getInit()
            }}
            className="!text-blue-500"
            icon={<ReloadOutlined className="text-blue-500" />}
          />
        </Col>
      </Row>
      <Table
        loading={loading}
        dataSource={data}
        rowKey={"username"}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Username",
            dataIndex: "username",
            key: "username",
          },
          {
            title: "Level",
            dataIndex: "level",
            key: "level",
          },
          {
            title: "Google Authentication QR",
            dataIndex: "raw",
            key: "raw",
            render: (src) => <QRCode value={src} errorLevel="H" />,
          },
          {
            title: "",
            dataIndex: "user_id",
            key: "user_id",
            render: (i, row) => {
              return (
                <div className="flex justify-start items-center">
                  {canEdit && (
                    <Button
                      type="link"
                      onClick={() => setModal({ mode: "update", handleSubmit: handleUpdate, visible: true, data: row })}
                      className="!text-blue-600"
                      icon={<EditFilled className="text-blue-600" />}
                    />
                  )}
                  {canDelete && (
                    <Popconfirm
                      placement="top"
                      title={"ยืนยันลบผู้ใช้งาน"}
                      onConfirm={() => handleDelete(i)}
                      okText="Yes"
                      cancelText="No">
                      <Button type="link" className="!text-red-500" icon={<DeleteFilled className="text-red-500" />} />
                    </Popconfirm>
                  )}
                  {canResetOtp && (
                    <Popconfirm
                      placement="top"
                      title={"ยืนยัน Reset OTP"}
                      onConfirm={() => handleResetOtp(i)}
                      okText="Yes"
                      cancelText="No">
                      <Button
                        type="link"
                        className="!text-red-500"
                        icon={<QrcodeOutlined className="text-orange-400" />}
                      />
                    </Popconfirm>
                  )}
                </div>
              )
            },
          },
        ]}
      />

      {modal.visible && <AmendUserModal {...modal} onCancel={() => setModal(initModal)} />}
    </>
  )
}

export default Users
