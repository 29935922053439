/* eslint-disable no-useless-concat */
import axios from "axios";
import dayjs from "dayjs";
import { authenticate, logout } from "./auth";
const BASE = process.env.REACT_APP_API_ENDPOINT;
axios.interceptors.response.use(
  (response) => {
    const accessToken = response?.data?.access_token?.accessToken;
    if (accessToken) {
      window.localStorage.setItem("access_token", accessToken);
    }
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

const request = ({ path, method = "get", body = {} }) => {
  const auth = authenticate();
  const config = {
    url: `${BASE}${path}`,
    method,
    headers: {
      Authorization: auth.accessToken && `Bearer ${auth.accessToken}`,
      "refresh-token": auth.refreshToken && `Bearer ${auth.refreshToken}`,
    },
    data: body,
  };

  return axios(config);
};

//USER
export const login = async (username, password) => {
  return request({
    method: "post",
    path: "/users/authenticate",
    body: { username, password },
  });
};
export const getAllUser = async () => {
  return request({ method: "get", path: "/users/all-user" });
};
export const createUser = async (body) => {
  return request({ method: "post", path: "/users/create", body });
};
export const updateUser = async (body) => {
  return request({ method: "post", path: "/users/update", body });
};
export const resetUserOtp = async (id) => {
  return request({ method: "get", path: "/users/reset-opt" + "/" + id });
};
export const resetAllUserOtp = async () => {
  return request({ method: "get", path: "/users/reset-opt" });
};
export const deleteUser = async (id) => {
  return request({ method: "get", path: "/users/delete" + "/" + id });
};

//LIST
export const getCount = async (adminId) => {
  return request({
    method: "get",
    path: `/data/dashboard${adminId ? `/${adminId}` : ""}`,
  });
};
export const getAllAdmin = async () => {
  return request({ method: "get", path: "/users/admin/list" });
};
export const getColor = async () => {
  return request({ method: "get", path: "/data/colors" });
};
export const getAllDates = async () => {
  return request({ method: "get", path: "/data/list-date" });
};
export const getMasterData = async (date, color, admin) => {
  return request({
    method: "post",
    path: "/data",
    body: {
      date: date
        ? dayjs(date).tz("Asia/Bangkok").format("YYYY-MM-DD")
        : undefined,
      o: color ? parseInt(color) : undefined,
      admin: admin ? parseInt(admin) : undefined,
    },
  });
};
export const requestClaim = async (id) => {
  return request({ method: "get", path: "/data/claim" + "/" + id });
};
export const deleteGreen = async (adminId) => {
  return request({
    method: "get",
    path: `/data/reset${adminId ? `/${adminId}` : ""}`,
  });
};
export const resetTime = async (adminId) => {
  return request({ method: "get", path: "/data/resetl" + "/" + adminId });
};
export const deleteById = async (id) => {
  return request({ method: "get", path: "/data/delete" + "/" + id });
};
export const saveHistory = async (admin, date) => {
  return request({
    method: "post",
    path: "/data/save/history",
    body: {
      admin,
      date,
    },
  });
};
export const updateMasterData = async (body) => {
  return request({
    method: "post",
    path: "/data/update",
    body,
  });
};
export const createMasterData = async (body) => {
  return request({
    method: "post",
    path: "/data/add",
    body,
  });
};
export const assignAdmin = async (adminId, ids) => {
  return request({
    method: "post",
    path: "/data/admin/update",
    body: {
      admin: adminId,
      ids: ids,
    },
  });
};
export const historyData = async (id) => {
  return request({
    method: "get",
    path: "/data/history/2months" + "/" + id,
  });
};
export const allowDowntime = async (id) => {
  return request({
    method: "get",
    path: "/data/allow/down/time" + "/" + id,
  });
};
export const notAllowDowntime = async (id) => {
  return request({
    method: "get",
    path: "/data/not/allow/down/time" + "/" + id,
  });
};
export const allowOpenTime = async (id) => {
  return request({
    method: "get",
    path: "/data/allow/open/time" + "/" + id,
  });
};
export const notAllowOpenTime = async (id) => {
  return request({
    method: "get",
    path: "/data/not/allow/open/time" + "/" + id,
  });
};
export const allowK = async (id) => {
  return request({
    method: "get",
    path: "/data/allow/k/permission" + "/" + id,
  });
};
export const notAllowK = async (id) => {
  return request({
    method: "get",
    path: "/data/not/allow/k/permission" + "/" + id,
  });
};
export const allowKAll = async (id) => {
  return request({
    method: "get",
    path: "/data/allow/k/permission/admin" + "/" + id,
  });
};
export const notAllowKAll = async (id) => {
  return request({
    method: "get",
    path: "/data/not/allow/k/permission/admin" + "/" + id,
  });
};
export const clearK = async (id) => {
  return request({
    method: "get",
    path: "/data/clear/k/" + id,
  });
};
export const clearK1 = async (id) => {
  return request({
    method: "get",
    path: "/data/clear/k1/" + id,
  });
};
export const allowOldUser = async (id) => {
  return request({
    method: "get",
    path: "/data/allow/old/user" + "/" + id,
  });
};
export const notAllowOldUser = async (id) => {
  return request({
    method: "get",
    path: "/data/not/allow/old/user" + "/" + id,
  });
};

//TEL PERMISSION
export const getAllTelPermission = async () => {
  return request({ method: "get", path: "/data/claim/all/view" });
};
export const approvePermission = async (id) => {
  return request({ method: "post", path: `/data/agree/${id}` });
};

//DASHBOARD
export const getDashboard = async () => {
  return request({ method: "get", path: "/users/admin/dashboard" });
};

//TRASH
export const getTrashedList = async () => {
  return request({ method: "post", path: "/data/notactive" });
};
export const revertList = async (id) => {
  return request({ method: "get", path: `/data/reactive/${id}` });
};
