import { Button, Col, Popconfirm, Row, Table, Tag, Typography } from "antd"
import React, { useEffect, useState } from "react"
import * as requests from "../utils/requests"
import dayjs from "dayjs"
import { SettingOutlined, ReloadOutlined } from "@ant-design/icons"
import notification from "../component/notification"

const TelPermission = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getInit()
  }, [])

  const getInit = async () => {
    setLoading(true)
    try {
      const res = await requests.getAllTelPermission()
      setData(res?.data?.data || [])
    } catch (e) {
      setData([])
    }
    setLoading(false)
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      name: "id",
      editable: false,
    },
    {
      title: "ผู้ที่ขออนุญาติ",
      dataIndex: "id_user",
      key: "id_user",
      name: "id_user",
      editable: false,
    },
    {
      title: "รหัสรายการ",
      dataIndex: "id_table",
      key: "id_table",
      name: "id_table",
      editable: false,
    },
    {
      title: "วัน/เวลาที่อนุญาติ",
      dataIndex: "datetime_allow",
      key: "datetime_allow",
      name: "datetime_allow",
      editable: false,
      render: (i) => (i ? dayjs(i).format("DD MMM BBBB HH:mm") : ""),
    },
    {
      title: "วัน/เวลาที่ส่งคำขอ",
      dataIndex: "datetime_create",
      key: "datetime_create",
      name: "datetime_create",
      editable: false,
      render: (i) => (i ? dayjs(i).format("DD MMM BBBB HH:mm") : ""),
    },
    {
      title: "สถานะ",
      dataIndex: "confirm",
      key: "confirm",
      name: "confirm",
      editable: false,
      render: (i) => <Tag color={i ? "success" : "error"}>{i ? "อนุญาติ" : "ไม่อนุญาติ"}</Tag>,
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      name: "id",
      editable: false,
      render: (i, { confirm }) =>
        !confirm && (
          <Popconfirm
            placement="top"
            title={"ยืนยันอนุญาติ"}
            onConfirm={() => handleApprove(i)}
            okText="ยืนยัน"
            cancelText="ยกเลิก">
            <Button icon={<SettingOutlined />} type="ghost" />
          </Popconfirm>
        ),
    },
  ]
  const handleApprove = async (id) => {
    try {
      await requests.approvePermission(id)
      notification("success", "อนุญาติสำเร็จ")
      getInit()
    } catch (e) {
      notification("error", "อนุญาติไม่สำเร็จ")
    }
  }
  return (
    <>
      <Typography.Title level={4}>ขออนุญาติ</Typography.Title>
      <Row justify="end">
        <Col>
          <Button
            type="link"
            onClick={getInit}
            className="!text-blue-500"
            icon={<ReloadOutlined className="text-blue-500" />}
          />
        </Col>
      </Row>
      <Table columns={columns} loading={loading} dataSource={data} rowKey="id" />
    </>
  )
}

export default TelPermission
