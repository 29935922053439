import dayjs from "dayjs"
import FileSaver from "file-saver"
import * as XLSX from "xlsx"

const saveAsExcel = ({ data, header, callback }) => {
  const fileType = "xlsx"
  const ws = XLSX.utils.book_new()
  XLSX.utils.sheet_add_aoa(ws, [header])
  XLSX.utils.sheet_add_json(ws, data)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: fileType, type: "array", cellStyles: true })
  const finalData = new Blob([excelBuffer], { type: fileType })
  try {
    FileSaver.saveAs(finalData, `รายการ-${dayjs().format("DD-MM-YYYY_hh:mm")}.xlsx`)
    callback()
  } catch (e) {}
}
export default saveAsExcel
