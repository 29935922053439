import { ConfigProvider } from "antd";
import thTH from "antd/locale/th_TH";
import dayjs from "dayjs";
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React from "react";
import {
  Navigate,
  Route,
  HashRouter as Router,
  Routes,
} from "react-router-dom";
import Dashboard from "./container/Dashboard";
import List from "./container/List";
import Login from "./container/Login";
import Permission from "./container/TelPermission";
import Trash from "./container/Trash";
import Users from "./container/Users";
import History from "./container/History";
import PrivateRoutes from "./utils/PrivateRoutes";
import { config } from "./utils/config";
dayjs.extend(buddhistEra);
dayjs.locale("th");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Bangkok");

const App = () => {
  return (
    <ConfigProvider locale={thTH}>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<List key="list" />} path="/list" />
            <Route element={<Users />} path="/users" />
            <Route element={<Permission />} path="/tel-permission" />
            <Route element={<Dashboard />} path="/dashboard" />
            <Route element={<Trash />} path="/trash" />
            <Route element={<History />} path="/history/:id" />
          </Route>
          <Route element={<Login />} path="/login" />
          <Route element={<Navigate to={config.defaultListPath} />} path="*" />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;
