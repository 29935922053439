import { Button, Col, Row, Table, Typography, Form } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import * as requests from "../utils/requests";
import { EyeOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import hexToRgba from "hex-to-rgba";
import dayjs from "dayjs";

export const colorItem = (data) => {
  return (
    data?.color_code && (
      <Row gutter={[8, 0]} align="middle">
        <Col style={{ margin: "auto 0" }}>
          <div
            className={`w-6 h-6 rounded-full m-auto shadow-xl border-solid border-2 border-gray-100 `}
            style={{ backgroundColor: data?.color_code }}
          />
        </Col>
        <Col>{data?.color_name}</Col>
      </Row>
    )
  );
};

const EditableCell = ({
  editing,
  editable,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  options,
  colorList,
  widthSize = "small",
  ...restProps
}) => {
  const style = useMemo(() => {
    const bg = colorList?.find((c) => c?.id === record?.o)?.color_code;
    return bg
      ? { backgroundColor: hexToRgba(bg, dataIndex === "amend" ? 1 : 0.5) }
      : {};
  }, [record?.o, colorList, dataIndex]);
  return (
    <td {...restProps} style={{ ...style, ...restProps?.style }}>
      {editable && editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const History = ({ hideHeader = false }) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [URLSearchParams] = useSearchParams();
  const admin = URLSearchParams?.get("admin");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // const isOnlyColorName = params?.get("isOnlyColorName");
  // const isNotOnlyColorName = params?.get("isNotOnlyColorName");

  const [colorList, setColorList] = useState([]);

  const getInit = async () => {
    setLoading(true);
    try {
      const res = await requests.historyData(id);
      setData(res?.data?.data || []);
    } catch (e) {
      setData([]);
    }
    setLoading(false);
  };

  const getColorList = async () => {
    try {
      const res = await requests.getColor();
      // if (isOnlyColorName) {
      //   const tempColors = res?.data?.data?.filter(
      //     (c) => c?.color_name === isOnlyColorName
      //   );
      //   setColorList(tempColors);
      //   // setSelectedColor([tempColors?.[0]?.id]);
      // } else if (isNotOnlyColorName) {
      //   setColorList(
      //     res?.data?.data?.filter((c) => c?.color_name !== isNotOnlyColorName)
      //   );
      //   // setSelectedColor([]);
      // } else {
      setColorList(res?.data?.data);
      // }
    } catch (e) {
      setColorList([]);
    }
  };

  const getOptions = () => {
    // canFilterAdmin && getAdminList();
    getColorList();
    // canFilterColor && getColorList();
    // canFilterDate && getDateList();
  };

  const columns = [
    // {
    //   title: "ลำดับ",
    //   dataIndex: "no",
    //   key: "no",
    //   name: "no",
    //   editable: false,
    //   show: true,
    // },
    {
      title: "ID(รหัสลูกค้า)",
      dataIndex: "id",
      key: "id",
      name: "id",
      editable: false,
      show: true,
      render: (i) => (i === "new" ? "เพิ่มใหม่" : i),
    },
    {
      title: "Admin",
      dataIndex: "adminName",
      key: "adminName",
      name: "adminName",
      editable: false,
      //   show: canViewAdminColumn,
    },
    // เผือได้ใช้
    // {
    //   title: "พด.",
    //   dataIndex: "a",
    //   key: "a",
    //   name: "a",
    //   editable: canEditA,
    //   inputType: "text",
    //   show: true,
    // },
    // {
    //   title: "ร",
    //   dataIndex: "b",
    //   key: "b",
    //   name: "b",
    //   editable: canEditB,
    //   inputType: "text",
    //   show: true,
    // },
    // {
    //   title: "ด",
    //   dataIndex: "c",
    //   key: "c",
    //   name: "c",
    //   editable: canEditC,
    //   inputType: "text",
    //   show: true,
    // },
    {
      title: "ปจ",
      dataIndex: "d",
      key: "d",
      name: "d",
      //   editable: canEditD,
      inputType: "text",
      show: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "e",
      key: "e",
      name: "e",
      //   editable: canEditE,
      inputType: "text",
      show: true,
      widthSize: "large",
    },
    {
      title: "เลขบัตร",
      dataIndex: "citizen_id",
      key: "citizen_id",
      name: "citizen_id",
      // editable: canEditE,
      inputType: "text",
      show: true,
      widthSize: "large",
    },
    {
      title: "ราคา (ร)",
      dataIndex: "f_1",
      key: "f_1",
      name: "f_1",
      //   editable: canEditF,
      inputType: "text",
      show: true,
    },
    {
      title: "ราคา (ด)",
      dataIndex: "f",
      key: "f",
      name: "f",
      //   editable: canEditF,
      inputType: "text",
      show: true,
    },
    {
      title: "ลน.",
      dataIndex: "g",
      key: "g",
      name: "g",
      //   editable: canEditG,
      inputType: "text",
      show: true,
    },
    {
      title: "ปด.",
      dataIndex: "h",
      key: "h",
      name: "h",
      //   editable: canEditH,
      inputType: "text",
      show: true,
    },
    {
      title: "ว.ค้าง",
      dataIndex: "i",
      key: "i",
      name: "i",
      //   editable: canEditI,
      inputType: "text",
      show: true,
    },
    {
      title: "ก/ค",
      dataIndex: "j",
      key: "j",
      name: "j",
      //   editable: canEditJ,
      inputType: "text",
      show: true,
    },
    {
      title: "โอน(ร)",
      dataIndex: "k_1",
      key: "k_1",
      name: "k_1",
      //   editable: canEditK,
      inputType: "text",
      show: true,
    },
    {
      title: "โอน(ด)",
      dataIndex: "k",
      key: "k",
      name: "k",
      //   editable: canEditK,
      inputType: "text",
      show: true,
    },
    {
      title: "เวลา",
      dataIndex: "l",
      key: "l",
      name: "l",
      //   editable: canEditL,
      inputType: "text",
      show: true,
    },
    {
      title: "วันเปิด",
      dataIndex: "open_date",
      key: "open_date",
      name: "open_date",
      // editable: canEditL,
      inputType: "text",
      show: true,
      render: (date) => (date ? dayjs(date).format("DD MMM BBBB") : null),
    },
    {
      title: "วันล้ม",
      dataIndex: "down_date",
      key: "down_date",
      name: "down_date",
      // editable: canEditL,
      inputType: "text",
      show: true,
      render: (date) => (date ? dayjs(date).format("DD MMM BBBB") : null),
    },
    {
      title: "ยอดล้ม",
      dataIndex: "amount_down",
      key: "amount_down",
      name: "amount_down",
      // editable: canEditL,
      inputType: "text",
      show: true,
    },
    {
      title: "วันส่ง (ร)",
      dataIndex: "send_date_count",
      key: "send_date_count",
      name: "send_date_count",
      // editable: canEditL,
      inputType: "text",
      show: true,
    },
    {
      title: "Note",
      dataIndex: "m",
      key: "m",
      name: "m",
      //   editable: canEditM,
      inputType: "text",
      show: true,
      widthSize: "large",
    },
    {
      title: "วันที่ stamp",
      dataIndex: "stamp_date",
      key: "stamp_date",
      name: "stamp_date",
      // editable: canEditL,
      inputType: "text",
      show: true,
      render: (date) => (date ? dayjs(date).format("DD MMM BBBB") : null),
    },
    {
      title: "โทร",
      dataIndex: "n",
      key: "n",
      name: "n",
      //   editable: canEditN,
      inputType: "text",
      render: (i, row) =>
        !i ? (
          <Button
            // onClick={() => handleRequestTelAccess(row.id)}
            type="link"
            icon={<EyeOutlined className="text-yellow-600" />}
          />
        ) : (
          i
        ),
      show: true,
      widthSize: "large",
    },
    {
      title: "สี",
      dataIndex: "o",
      key: "o",
      name: "o",
      // editable: canEditO,
      render: (i) => colorItem(colorList?.find((c) => c?.id === i)),
      inputType: "select",
      // options: colorList.map((i) => ({ value: i?.id, label: colorItem(i) })),
      show: true,
      widthSize: "large",
    },
  ];

  const mergedColumns = columns
    .filter((i) => i?.show)
    .map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.inputType,
          widthSize: col.widthSize,
          dataIndex: col.dataIndex,
          title: col.title,
          // editing: isEditing(record),
          options: col.options,
          colorList,
          editable: col.editable,
        }),
      };
    });

  useEffect(() => {
    getInit();
    getOptions();
  }, []);

  return (
    <>
      <Button
        onClick={() =>
          navigate(`/list?isNotOnlyColorName=สีชมพูม่วง?admin=${admin}`)
        }
        type="primary"
        icon={<ArrowLeftOutlined />}
        className="flex justify-start items-center gap-1 mb-4">
        ย้อนกลับ
      </Button>

      {!hideHeader && (
        <Typography.Title level={4}>2 months history</Typography.Title>
      )}

      <Form form={form} component={false}>
        <Table
          columns={mergedColumns}
          loading={loading}
          dataSource={data}
          rowKey="admin_name"
          bordered
          className="mt-5"
          scroll={{ x: "max-content" }}
          pagination={{
            showSizeChanger: true,
            total: data.length,
            pageSizeOptions: [10, 20, 50, 100],
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          components={{
            body: {
              cell: ({ ...props }) => <EditableCell {...props} />,
            },
          }}
        // {...tableSelectionProps}
        />
      </Form>
    </>
  );
};

export default History;
